// variables

// mixins
=opacity($opacity)
	opacity: $opacity
	filter: alpha(opacity=$opacity*100)

=intop
	display: inline-block
	vertical-align: top

=transition($transition)
	-webkit-transition: $transition
	transition: $transition

=box
	-webkit-box-sizing: border-box
	box-sizing: border-box

=ct
	content: '';
	display: block

=box-shadow($box-shadow)
	-webkit-box-shadow: $box-shadow
	box-shadow: $box-shadow

=transform($transform)
	-webkit-transform: $transform
	-ms-transform: $transform
	transform: $transform



// classes
.text-right
	text-align: right

.text-center
	text-align: center

::moz-selection
    background: #B8B8B8

::selection
    background: #B8B8B8

button[type="submit"],
input[type="submit"]
	-webkit-appearance: none
	-moz-appearance: none
	appearance: none


@import url(http://fonts.googleapis.com/css?family=Lato:400,700,900)

body
	margin: 0
	font: 34px/1.05 'Lato', sans-serif

body, html
	height: 100%
	min-height: 100%
	overflow: visible

img
	max-width: 100%
	border: 0

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section, time 
	display: block

h1, h2, h3, h4, h5, h6, p 
	margin: 0

ul 
	margin: 0
	padding: 0

.container
	position: relative
	z-index: 1
	width: 1170px
	margin: auto

.nav
	position: fixed
	top: 39.5%
	left: 20px
	z-index: 3
	width: 100px
	
	li
		list-style: none
		width: 16px
		height: 16px
		margin-bottom: 20px
		border: #aaabaf 2px solid
		border-radius: 50px;
		+box
		background: #3b3c42
		cursor: pointer

		&:hover
			background: lighten(#3b3c42, 10%)

		&.active
			position: relative
			border: none
			background: rgba(0, 0, 0, 0.3)

			&:after
				+ct
				position: absolute
				left: 4px
				top: 4px
				width: 8px
				height: 8px
				border-radius: inherit
				background: #fff


// first-screen

.section
	position: relative
	min-height: 400px
	+box
	background-size: cover
	background-position: 0 bottom
	background-repeat: no-repeat

	&.section-2
		min-height: 700px
		padding: 198px 0 30px 0

		h2
			margin-bottom: 9px

.vertical-align
	display: table
	width: 100%
	height: 100%

.first-screen
	min-height: 500px
	padding: 30px 0
	background: url(../img/section-1.jpg)
	background-size: cover
	background-attachment: fixed
	color: #fff
	text-align: center

	.color-overlay
		background: #2c2f39

	h1
		margin: 59px 0
		font-weight: 400
		font-size: 48px
		line-height: 1.2

	.btn
		margin: 35px 0

.logo
	display: inline-block

.color-overlay
	position: absolute
	top: 0
	left: 0
	z-index: 0
	width: 100%
	height: 100%
	+opacity(0.8)

.middle-cell
	display: table-cell
	vertical-align: middle

.btn
	+intop
	padding: 25px 51px 30px 65px
	border-radius: 40px
	background: #f26e71
	font-size: 24px
	letter-spacing: 0.03em
	color: #fff
	text-transform: uppercase
	text-decoration: none
	text-shadow: none
	+transition(all 0.2s ease-out)

	&:after
		content: '';
		+intop
		width: 27px
		height: 15px
		margin: 6px 0 0 18px
		background: url(../img/btn-arrow.png) 0 0 no-repeat


	&:hover
		background: darken(#f26e71, 5%)

.middle-line
	position: absolute
	left: 50%
	width: 2px
	background: #fff
	+opacity(0.2)

	.circle
		position: absolute
		width: 16px
		height: 16px
		left: -7px
		border: #fff 2px solid
		border-radius: 40px
		+box

.first-screen .middle-line
	height: 8%
	bottom: 0

	.circle
		top: -16px


// second-screen

.second-screen
	padding: 30px 0
	background: url(../img/section-2.jpg)
	background-size: cover
	background-attachment: fixed
	text-align: center

	.color-overlay
		background: #0e3a39
		+opacity(0.6)

	.middle-line
		top: 0
		height: 100%

		.circle
			bottom: 10%
			background: #33483f

.section
	color: #fff
	text-shadow: 3px 4px 0 rgba(0, 0, 0, 0.1)
	overflow: hidden

	&.light
		color: #54565f
	
	h2
		margin-bottom: 0px
		font-weight: 800
		font-size: 52px
		line-height: 1.2
		text-transform: uppercase


		small
			display: block
			margin-top: 14px
			font-weight: 400
			font-size: 34px
			text-transform: none
	
	.slider
		position: relative
		min-height: 186px

	.slider-heading
		position: absolute
		top: 0
		left: 0
		width: 100%
		display: none
		margin: 38px 0 40px 0

		&.active
			display: block

		span
			display: inline-block
			padding: 23px 49px

			&.color-1
				background: #49c1c3

			&.color-2
				background: #7d7da7

			&.color-3
				background: #f5bb5b

			&.color-4
				background: #e8dd76

			&.color-5
				background: #f26f70

			&.color-6
				background: #BCDFCB

			&.color-7
				background: #54565e

	p
		line-height: 1.2



// map-section

.map-section
	position: relative
	overflow: hidden

	&.section.section-2
		height: 700px
		min-height: 1px

	.color-overlay
		z-index: 1
		background: #fff
		+opacity(0.5)

	.middle-line
		top: 0
		height: 58%
		background: #54565f
		+opacity(0.1)

	.middle-line-2
		height: 21.6%
		top: auto
		bottom: 0

	.circle-big
		background: url(../img/circle-big.png)

.map, .map-icons
	position: absolute
	top: 0px
	left: -154px	

.map
	z-index: 0
	width: 2100px
	height: 100%

.map-bg
	position: absolute
	top: 0
	left: 0
	z-index: 0
	display: block
	width: auto
	height: 700px
	width: auto
	max-width: 1000%

.map-icons
	z-index: 2
	height: 100%
	width: 800px

.map-icon
	display: block
	position: absolute
	z-index: 1
	cursor: pointer
	+transition(all 0.1s ease-out)

	&:hover
		+transform(scale(1.05))

	&.map-icon-1
		top: 124px
		left: 371px

	&.map-icon-2
		top: 107px
		left: 522px

	&.map-icon-3
		top: 207px
		left: 275px

	&.map-icon-4
		top: 234px
		left: 455px

	&.map-icon-5
		top: 198px
		left: 663px

	&.map-icon-6
		top: 313px
		left: 627px

	&.map-icon-7
		top: 396px
		left: 316px

.circle-big
	position: absolute
	bottom: -144px
	left: -64px
	width: 130px
	height: 144px
	background-position: 0 0
	background-repeat: no-repeat
	background-size: cover

.map-slide
	position: absolute
	top: 0
	right: -1500px
	+transition(all 0.6s ease-out)

	&.active
		right: 0



// allow-public

.feedback
	background: #545487 url(../img/section-allow-public.jpg) bottom right no-repeat
	background-size: cover

	&.section.section-2
		padding-bottom: 0

	.color-overlay
		background: #5b5b9b
		+opacity(0.8)

	.middle-line
		top: 18.5%
		height: 33%
		background: #fff
		+opacity(0.2)

		.circle-big
			background: url(../img/circle-big-white.png)

	.middle-line-top
		top: 0
		height: 18.5%
		background: #eeeeef
		+opacity(1)

		.circle
			top: auto
			bottom: 0
			background: #525285

	.middle-line-2
		top: 72%
		height: 28%

	.container
		// min-height: 502px

.iphone-container
	position: absolute
	bottom: 0
	left: 0
	width: 100%

.iphone
	position: absolute
	bottom: 0
	right: 45px
	width: 426px
	overflow: hidden

	img
		display: block

.iphone-bg
	position: relative
	z-index: 1
	width: 100%

.iphone-content
	position: absolute
	top: 18%
	left: 7%
	z-index: 0
	width: 87%


// competition

.competition
	background: url(../img/section-competition.jpg)
	background-size: cover
	background-attachment: fixed
	
	.color-overlay
		background: #46c2c3
		+opacity(0.7)

	.middle-line
		top: 0
		height: 100%
		background: #fff
		+opacity(0.2)

		.circle
			top: 37%
			background: #7dd4d5

.competition-img
	float: left
	margin: -52px 0 0 21px


// contact

.contact
	padding: 144px 0 50px 0
	background: url(../img/section-contact.jpg) 0 bottom no-repeat
	background-size: cover

	.color-overlay
		background: #54565f
		+opacity(0.8)

	.middle-line
		top: 0
		height: 10%
		background: #fff
		+opacity(0.2)

		.circle
			bottom: -16px

.success
	display: none
	width: 60%
	margin: 40px auto -30px auto
	padding: 15px 0
	border-radius: 5px
	background: #1CDC40
	background: rgba(23, 237, 63, 0.7)
	+transition(all 0.4s ease-out)

	&.error
		background: #FF0000
		background: rgba(255, 0, 0, 0.8)

.contact-form
	display: block
	width: 490px
	margin: 70px auto 20px auto

	input[type="text"],
	textarea,
	input[type="submit"]
		display: block
		width: 100%
		height: 60px
		padding: 15px 21px
		border: #42444c 1px solid
		border-radius: 8px
		+box
		margin-bottom: 20px
		background: #42444c
		font-family: inherit
		font-size: 20px
		color: #fff
		outline: none
		+transition(all 0.2s ease-out)

	input[type="text"]:focus,
	textarea:focus
		border-color: #fff
		+box-shadow(0 0 3px 0 #fff)


	input[type="text"]
		

	textarea
		height: 185px
		padding-right: 10px
		

	input[type="submit"]
		border: none
		background: #46c2c3
		text-transform: uppercase
		text-align: center
		cursor: pointer

		&:hover
			background: darken(#46c2c3, 5%)

		&:focus
			+box-shadow(0 3px 4px 0 rgba(0, 0, 0, 0.1) inset)
			color: darken(#fff, 10%)

	.error
		position: relative
		float: left
		display: none
		margin: -15px 0 10px 0
		padding: 10px 20px
		border-radius: 5px
		background: #FF0000
		background: rgba(255, 0, 0, 0.8)
		+box-shadow(1px 2px 2px 0 rgba(0, 0, 0, 0.3))
		font-size: 20px
		text-align: left

		&:before
			+ct
			position: absolute
			top: -17px
			left: 20px
			border: transparent 10px solid
			border-bottom: #ff0000 7px solid
			border-bottom: rgba(255, 0, 0, 0.8) 7px solid

// footer

.footer
	padding-top: 105px
	text-align: center
	
	p
		margin-top: 28px
		font-size: 14px
		color: #abadb2







	


























































